import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { FileIconPipe } from './pipes/file-icon.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MenubarModule } from 'primeng/menubar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { AuthorizeDirective } from './directives/authorize.directive';
import { StringFormatPipe } from './pipes/string-format.pipe';
import { UserFormatPipe } from './pipes/user-format.pipe';
import { BooleanFormatPipe } from './pipes/boolean-format.pipe';
import {
    DropdownModule, MultiSelectModule, RadioButtonModule, CalendarModule, CheckboxModule, TooltipModule, InputTextModule, TreeModule, ScrollPanelModule, AutoCompleteModule, PaginatorModule, BlockUIModule, MenuModule, InputTextareaModule, SpinnerModule,
    InputSwitchModule, ChipsModule, SliderModule, CardModule, OverlayPanelModule, EditorModule, InputMask, InputMaskModule, TriStateCheckboxModule
} from 'primeng';
import { ApprovalPipe } from './pipes/approval.pipe';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { SummaryPipe } from './pipes/summary.pipe';
import { AutoCompleteComponent } from './components/autocomplete/input.component';
import { AutocompleteDatasourceComponent } from './components/autocomplete/autocomplete-datasource/autocomplete-datasource.component';
import { ValidationInlineComponent } from './components/validation-inline/validation-inline.component';
import { TrangThaiPipe } from './pipes/trangThai.pipe';
import { HighlightSearchPipe } from './pipes/highlight.pipe';
import { SvBreadcrumbComponemt } from './components/breadcrumb/breadcrumb.componemt';
import { FileExplorerComponent } from './components/file-manager/file-explorer/file-explorer.component';
import { FileFormComponent } from './components/file-manager/file-form/file-form.component';
import { FolderFormComponent } from './components/file-manager/folder-form/folder-form.component';
import { FileUploadComponent } from './components/file-manager/file-upload/file-upload.component';
import { FileViewerComponent } from './components/file-manager/file-viewer/file-viewer.component';
import { FileExplorerModalComponent } from './components/file-manager/file-explorer-modal/file-explorer-modal.component';

export function coreDeclaration() {
    return [
        FileIconPipe,
        FileSizePipe,
        StringFormatPipe,
        UserFormatPipe,
        BooleanFormatPipe,
        AuthorizeDirective,
        ApprovalPipe,
        ValidationSummaryComponent,
        AutofocusDirective,
        SummaryPipe,
        AutoCompleteComponent,
        AutocompleteDatasourceComponent,
        ValidationInlineComponent,
        TrangThaiPipe,
        HighlightSearchPipe,
        SvBreadcrumbComponemt,
        FileExplorerComponent,
        FileExplorerModalComponent,
        FileFormComponent,
        FileUploadComponent,
        FolderFormComponent,
        FileViewerComponent
    ];
};

export function coreModuleImport() {
    return [
        CommonModule,
        DialogModule,
        ButtonModule,
        BreadcrumbModule,
        DropdownModule,
        MultiSelectModule,
        TabViewModule,
        DataViewModule,
        TooltipModule,
        ToastModule,
        MenubarModule,
        ContextMenuModule,
        TableModule,
        ConfirmDialogModule,
        FileUploadModule,
        FormsModule,
        RadioButtonModule,
        CalendarModule,
        CheckboxModule,
        InputTextModule,
        ReactiveFormsModule,
        TranslateModule,
        TreeModule,
        ScrollPanelModule,
        AutoCompleteModule,
        PaginatorModule,
        BlockUIModule,
        MenuModule,
        InputTextareaModule,
        SpinnerModule,
        EditorModule,
        InputSwitchModule,
        TableModule,
        ToastModule,
        DialogModule,
        ReactiveFormsModule,
        ChipsModule,
        SliderModule,
        CardModule,
        OverlayPanelModule,
        InputMaskModule,
        TriStateCheckboxModule
    ];
}

@NgModule({
    imports: coreModuleImport(),
    providers: [

    ],
    declarations: coreDeclaration(),
    exports: [...coreDeclaration(), ...coreModuleImport()]
})
export class SvComponentModule {   
}
