import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FsFoldersService } from '../../../services/fs-folders.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';

@Component({
    selector: 'app-folder-form',
    templateUrl: './folder-form.component.html',
    styleUrls: ['./folder-form.component.scss']
})
export class FolderFormComponent extends SecondPageEditBase implements OnInit {
    parentFolderId = 0;
    isGlobal = false;
    constructor(
        protected _FsFoldersService: FsFoldersService,
        protected _injector: Injector
    ) {
        super(_FsFoldersService, _injector);

        this.formGroup = new FormGroup({
            ten: new FormControl('', Validators.compose([Validators.required])),
            trangThai: new FormControl('')
        });
    }

    ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    async onShowPopup(data: any) {
        this.parentFolderId = data.parentFolderId;
        this.isGlobal = data.isGlobal;
        this.submitting = true;
        this.validationSummary.resetErrorMessages();
        if (data.id > 0) {
            this.popupHeader = 'Sửa thông tin ';
            this._FsFoldersService.getDetail(data.id).then(
                rs => {
                    this.submitting = false;
                    if (rs.success) {
                        this.itemDetail = rs.data;
                    }
                },
                error => {
                    this._notifierService.showWarning(
                        'Không tìm thấy bản ghi được yêu cầu'
                    );
                    this.submitting = false;
                }
            );
        } else {
            this.itemDetail = { id: 0 };
            this.submitting = false;
        }
    }

    onInsert() {
        this._FsFoldersService.createFolder(this.parentFolderId, this.isGlobal, this.itemDetail.ten)
            .then(response => {
                if (this.continueAdding) {
                    this.resetForm();
                } else {
                    this.closePopupMethod(true);
                }

                this._notifierService.showInsertDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, error => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }
}

