<p-dialog #dialog [header]="'Cập nhật thông tin thư mục'" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row" *ngIf="invalid">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="ui-g -large-gutter -row sv-form-row">
            </div>

            <div class="ui-g -large-gutter -row sv-form-row">
                <div class="ui-g-12 ui-lg-6">
                    <div class="sv-form-item -required -error">
                        <div class="sv-form-item__label" for="ten">{{'Tên' | translate}}</div>
                        <div class="sv-form-item__input-wrapper -type-text-field">
                            <input class="sv-form-item__input sv-text-field" type="text" [(ngModel)]="itemDetail.ten"
                                formControlName="ten" placeholder="Tên ">
                        </div>
                    </div>
                </div>
                <div class="ui-g-12 ui-lg-6">
                    <div class="sv-form-item">
                        <div class="sv-form-item__label" for="trangThai">{{'Trạng thái dữ liệu' | translate}}</div>
                        <div class="sv-form-item__input-wrapper">
                            <p-dropdown class="sv-form-item__input sv-dropdown" panelStyleClass="sv-dropdown-panel"
                                formControlName="trangThai" [options]="arrTrangThai" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.trangThai"
                                [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="delete()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    authorize="[{'svcore':'positions/3'}]"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash -focus-style-1 ">
                    <span class="sv-btn__label">{{'Xóa' | translate}}</span>
                    <span class="sv-btn__icon ci ci-delete sv-color--red-60"></span>
                </button>
            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" *ngIf="itemDetail.id === 0" [disabled]="submitting"
                    authorize="[{'svcore':'positions/1'}]"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'FORM_SAVE_CLOSE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
                <button type="button" (click)="save()" *ngIf="itemDetail.id === 0" [disabled]="submitting"
                    authorize="[{'svcore':'positions/1'}]"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'FORM_SAVE_CONTINUE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
                <button type="button" (click)="saveAndClose()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    authorize="[{'svcore':'positions/2'}]"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'FORM_SAVE' | translate}}</span>
                    <span class="sv-btn__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>