import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Dialog } from 'primeng/dialog';
declare var DocsAPI: any;

@Component({
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

    @Input() FileUrl = '';
    @Input() FileName = '';

    @Output() closePopup = new EventEmitter<any>();

    @ViewChild('dialog') dialog: Dialog;

    isShow = false;
    isImage = false;
    isMaximized = false;

    constructor(
        private _elementRef: ElementRef
    ) {

    }

    ngOnInit() {

    }

    initDocumentViewer(fileType, documentType, fileUrl) {
        console.log(fileUrl);
        
        const config = {
            'height': '100%',
            'width': '100%',
            'documentType': `${documentType}`,
            'editorConfig': {
                'mode': 'view',
                'customization': {
                    'chat': false,
                    'compactToolbar': true,
                    // 'logo': {
                    //     'image': `${logoUrl}`,
                    //     'imageEmbedded': `${logoUrl}`
                    // },
                    'about': false,
                    'comments': false
                }
            },
            'document': {
                'permissions': {
                    'comment': false,
                    'download': true,
                    'edit': false,
                    'print': true,
                    'review': false,
                    'reader': true
                },
                'fileType': `${fileType}`,
                'title': `${this.FileName}`,
                'url': `${fileUrl}`
            }
        };
        const docEditor = new DocsAPI.DocEditor('file-viewer', config);
    }

    getFileType(fileName: string) {
        const arr = fileName.split('.');
        return arr[arr.length - 1];
    }

    getDocumentType(fileType: string) {
        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return 'image';
        } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return 'text';
        } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return 'spreadsheet';
        } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return 'presentation';
        }

        return '';
    }

    showPopup(status: boolean, data: any = {}) {
        this.isShow = status;
        if (data.fileName) {
            this.FileName = data.fileName;
        }

        if (data.fileUrl) {
            this.FileUrl = data.fileUrl;
        }

        const fileType = this.getFileType(this.FileName);
        const documentType = this.getDocumentType(fileType);

        if (documentType === 'image') {
            this.isImage = true;
        } else {
            this.isImage = false;
            setTimeout(() => {
                // this.dialog.maximize();
                var view = document.getElementById("fileViewContent").parentElement.style;
                view.height = "calc(100% - 50px)";
                view.margin = "-15px";
                this.initDocumentViewer(fileType, documentType, data.fileUrl);
            }, 500);
        }
    }
}

