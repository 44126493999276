<div class="sv-page-container" [ngClass]="{
                '-expansion-mode-active': app.pageContentExpansionModeActive,
                '-expansion-mode-inactive': !app.pageContentExpansionModeActive,
                '-menu-collapsed-in-desktop': app.staticMenuDesktopInactive,
                '-menu-expanded-in-desktop': !app.staticMenuDesktopInactive,
                '-menu-collapsed-in-small-device': !app.staticMenuMobileActive,
                '-menu-expanded-in-small-device': app.staticMenuMobileActive
                }">
    <app-topbar></app-topbar>

    <div class="sv-page-body-area">
        <div class="sv-page-menu-area">
            <app-menu-search-box></app-menu-search-box>

            <div class="sv-page-menu-wrapper">
                <div class="sv-page-menu-wrapper__overflow-panel">
                    <app-menu></app-menu>
                </div>
            </div>
        </div>

        <div class="sv-page-content-area-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="sv-page-overlay"></div>
</div>


<p-toast styleClass="sv-toast -style-inline" position="bottom-right" key="success-warn"></p-toast>

<p-toast styleClass="sv-toast" position="bottom-right" key="general-warning"></p-toast>

<p-toast styleClass="sv-toast" [style]="{marginTop: '60px'}" position="top-right" key="general-info"></p-toast>

<p-toast styleClass="sv-toast" [style]="{marginTop: '60px'}" position="top-right" key="error"></p-toast>

<p-confirmDialog [autoZIndex]="true"></p-confirmDialog>