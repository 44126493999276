import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../../../src/environments/environment';
import { SignalRService } from './signalr.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService extends BaseService {

    readonly storage = sessionStorage;
    setupSignalr = false;
    constructor(
        http: HttpClient,
        injector: Injector,
        private _userService: UserService,
        private _authenticationService: AuthenticationService,
        private _signalRService: SignalRService
    ) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/AzPermissions`);
        // this._signalRService.initCommonHub();
        // if (!this.setupSignalr) {
        //     this._signalRService.start(
        //         null,
        //         'CommonHub',
        //         this.refreshPermissions.bind(this)
        //     );

        //     this.setupSignalr = true;
        // }
    }

    async refreshPermissions() {
        console.log('refreshPermissions');
        for (const key in this.storage) {
            if (key.indexOf('AUTHORIZATION') >= 0) {
                await this.storage.removeItem(key);
            }
            if (key.indexOf('htMenu') >= 0) {
                this.storage.removeItem(key);
            }
        }
        await this.getsRoleAssignedToUserId();
    }

    isAuthorized(service, permission): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const currUser = this._authenticationService.getCurrentUser();
            if (currUser.issuperuser === 'True') {
                resolve(true);
            }

            this.getPermissions(service).then(permissions => {
                const isAuthorized = permissions.includes(permission);
                resolve(isAuthorized);
            }, error => {
                reject(false);
            });
        });
    }

    getPermissions(service): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${this.serviceUri}/GetsPermissionByMaAzService/${service}`;
            const key = `AUTHORIZATION.${service.toUpperCase()}`;

            const storageItem = this.storage.getItem(key);
            if (storageItem) {
                const result = JSON.parse(storageItem);
                resolve(result);
            } else {
                this.defaultGet(url).then(response => {
                    this.storage.setItem(key, JSON.stringify(response.data));
                    resolve(response.data);
                }, error => {
                    reject();
                });
            }
        });
    }

    getsRoleAssignedToUserId(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${environment.apiDomain.coreEndPoint}/AzRoles/GetsAssignedToUserId`;
            const key = `AUTHORIZATION.ROLES`;

            const storageItem = this.storage.getItem(key);
            if (storageItem) {
                const result = JSON.parse(storageItem);
                resolve(result);
            } else {
                this.defaultGet(url).then(response => {
                    this.storage.setItem(key, JSON.stringify(response.data));
                    resolve(response.data);
                }, error => {
                    reject();
                });
            }
        });
    }

    isLaSiAdmin(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const currUser = this._authenticationService.getCurrentUser();
            if (currUser.issuperuser === 'True') {
                resolve(true);
            } else {
                this.getsRoleAssignedToUserId().then(asRoles => {
                    const isAuthorized = asRoles.includes('LaSi.Admin') || asRoles.includes('LaSi.Manager') || asRoles.includes('LaSi.Viewer');
                    resolve(isAuthorized);
                }, error => {
                    reject(false);
                });
            }
        });
    }
}
