<div class="sv-page-login">
    <div class="sv-page-login-content-area">
        <div class="sv-page-login-content-area__header">
            <a class="sv-page-login-logo-wrapper" href="javascript:void(0)">
                <img src="{{environment.appMetadata.main.logo}}" class="sv-page-login-logo" />
            </a>

            <div class="sv-page-login-logo-accessory">
                <div class="sv-page-login-logo-accessory__title">{{environment.appMetadata.main.owner}}</div>

                <div class="sv-page-login-logo-accessory__slogan">{{environment.appMetadata.main.title}}</div>
            </div>
        </div>

        <div class="sv-page-login-content-area__body">
            <router-outlet></router-outlet>
        </div>

        <div class="sv-page-login-content-area__footer">
            <!-- <div class="h-cursor-pointer">{{'Website nhà trường' | translate}}</div>
            <div class="h-cursor-pointer">{{'Trung tâm CNTT' | translate}}</div> -->
        </div>
    </div>
</div>

<p-toast class="sv-toast" position="bottom-right" key="success-warn"></p-toast>