import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageEditBase, SvUser, UserService, SvCorePublicService } from 'sv-shared';
import { FormBuilder, Validators } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends SecondPageEditBase implements OnInit {
    model: ModelDangNhap = new ModelDangNhap();
    error = false;
    returnUrl: string;
    enableLogin = true;
    constructor(
        public translate: TranslateService,
        protected _injector: Injector,
        private _router: ActivatedRoute,
        private formBuilder: FormBuilder,
        private _oauthService: OAuthService,
        private _svCorePublicService: SvCorePublicService,
    ) {
        super(null, _injector);

        this.formGroup = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required],
            remember: [''],
            email: ['']
        });
    }

    async ngOnInit() {
        this.returnUrl = this._router.queryParams['returnUrl'] || '/';
        this._oauthService.requireHttps = false;
        if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
            // top.location.href = this.returnUrl;
        } else {
            localStorage.clear();
            sessionStorage.clear();
        }
    }

    changeLang(lang: string) {
        this.translate.use(lang.match(/vn|en/) ? lang : 'vn');
        localStorage.setItem('language', lang);
    }

    clickLogin() {
        this.submitting = true;
        if (this.isValid) {
            this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
                if (!rs) {
                    this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.username, this.model.password).then(result => {
                        this._oauthService.setupAutomaticSilentRefresh();
                        // this.authorizeService.savePermission(result);

                        this._notifierService.showSuccess(this._translateService.instant('LOGIN_OK'));
                        // this.app.getMenu();
                        setTimeout(() => {
                            this.submitting = false;
                            top.location.href = this.returnUrl;
                        }, 500);
                    },
                        error => {
                            console.log(error.error.error_description);
                            this._notifierService.showWarning(error.error.error_description);
                            setTimeout(() => {
                                this.submitting = false;
                            }, 500);
                        }
                    );
                }
            });

        } else {
            console.log('invalid');
        }

    }

    onFocus() {
        this.submitting = false;
        this.error = false;
    }
    goForgotPass() {
        this.enableLogin = !this.enableLogin;
    }

    onResetPassword() {
        if (this.model.username !== undefined && this.model.email !== undefined) {
            this._svCorePublicService.resetPassword(this.model).then(rs => {
                if (rs.success) {
                    this._notifierService.showSuccess(this._translateService.instant("LOGIN.SENDPASSOK"));
                } else {
                    this._notifierService.showWarning(rs.message);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant(error.error.error));
            });
        }

    }
}


class ModelDangNhap {
    username: string;
    password: string;
    remember: boolean;
    email: string;
}