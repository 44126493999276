<p-dialog #dialog [appendTo]="'body'" [header]="'FILE_EXPLORER.FILE_VIEWER.POPUP_TITLE' | translate" class="sv-modal"
    [(visible)]="isShow" [modal]="true" [responsive]="true" [styleClass]="'sv-modal force-full-width'"
    [style]="{'width': '1300px'}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div *ngIf="isImage" class="text-center" style="max-height:530px;overflow:scroll;">
            <img src="{{FileUrl}}" style="width:100%;" />
        </div>
        <div id="fileViewContent" style="display:none;">
        </div>
        <div id="file-viewer"></div>
    </div>
</p-dialog>