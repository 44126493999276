import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Router } from "@angular/router";
import { SignalRService, UserService, NotificationService } from 'sv-shared';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-notification',
    templateUrl: 'app.notification.component.html'
})
export class AppNotificationComponent implements OnInit {

    maxNotification = 10;
    totalUnRead = 0;
    url = '';
    urldetail = '';
    model = [];
    userId: string;
    currentItem: any;

    constructor(
        public app: AppComponent,
        private _router: Router,
        private _signalRService: SignalRService,
        private _userService: UserService,
        private _notificationService: NotificationService,
    ) {
        // this._signalRService.initNotificationHub();
    }

    ngOnInit(): void {
        this._userService.getId().then(rs => {
            this.userId = rs.toString();
            // this._signalRService.start(
            //     environment.signalrConfig.topic.notificationCreated,
            //     'NotificationHub',
            //     this.notifyTrigger.bind(this)
            // );

            // this.refreshNotification();
        });
    }

    hasChildren() {
        if (this.model) {
            for (let i = 0; i < this.model.length; i++) {
                let item = this.model[i];

                if (item.items) {
                    return true;
                }
            }
        }

        return false;
    }

    // helper
    //------------------------
    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    getRandomItemFromList(listItem) {
        let index = Math.floor(Math.random() * listItem.length);
        return listItem[index];
    }

    notifyTrigger(data: any) {
        // this.notifications.unshift(data);

        // if (this.notifications.length > this.maxNotification) {
        //     this.notifications.pop();
        // }
        // this.totalUnRead++;
        console.log(data);
        if (data !== null) {
            if (data.userId !== null) {
                if (data.userId.indexOf(',' + this.userId + ',') !== -1) {
                    console.log('refreshNotification:  ' + this.userId);
                    this.refreshNotification();
                }
            } else {
                console.log('refreshNotification:  All');
                this.refreshNotification();
            }
        }
    }

    refreshNotification() {
        this._notificationService.getsToUserId(Number(this.userId), 'false', 1, 9999)
            .then(result => {
                this.model = result.data;
                this.totalUnRead = result.totalRecord;
                sessionStorage.setItem('numOfNotifiUnRead', this.totalUnRead.toString());
            });
    }

    readAll(event) {
        event.preventDefault();
        event.stopPropagation();

        this._notificationService.readAll().then(rs => {
            this.model.forEach(element => {
                if (element.read != null) {
                    element.read.push({ userId: this.userId });
                } else {
                    element.read = [{ userId: this.userId }];
                }
            });
        });

        this.totalUnRead = 0;
    }

    readItem(item) {
        item.isRead = true;
        this._notificationService.readMessage(item.id).then(rs => {
        });
    }
}
