<p-dialog #dialog header="{{ 'DmNamHoc_TitleEdit' | translate }}" styleClass="sv-modal" [(visible)]="isShow"
    [modal]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div class="ui-g -large-gutter -row sv-form-row">
        </div>
        <div class="ui-g -large-gutter -row sv-form-row">
            <div class="ui-g-12 ui-lg-12">
                <p-fileUpload #fileControl [multiple]="true" mode="advanced" [uploadLabel]="'Tải file'"
                    [cancelLabel]="'Hủy tải lên'" [chooseLabel]="'Chọn nhiều file'" name="file" url="{{apiUploadUrl}}"
                    [maxFileSize]="maxFileSize" (onProgress)="onUploadProgress($event)" (onUpload)="onUpload($event)"
                    (onBeforeUpload)="onBeforeUpload($event)" (onUpload)="onUploaded($event)">
                    <ng-template pTemplate="content">
                        <ul *ngIf="uploadedFiles.length">
                            <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                        </ul>
                    </ng-template>
                </p-fileUpload>
            </div>
            <div class="ui-g-12 ui-lg-12 p-col">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progressValue + '%' }"></div>
                <p-progressBar [value]="progressValue"></p-progressBar>
            </div>
        </div>
    </div>
    <p-footer>
        <!--  <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">

            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block">
                <button type="button" (click)="saveAndClose()" * [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'FORM_SAVE' | translate}}</span>
                   <span class="sv-btn__icon ci ci-save"></span>
                </button>
            </div>
        </div> -->
    </p-footer>
</p-dialog>