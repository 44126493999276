<div class="sv-main-menu-search-box-container">
    <div class="sv-main-menu-search-box">
        <div class="sv-main-menu-search-box__icon ci ci-search"></div>

        <p-autoComplete [(ngModel)]="menuSearchInput" [suggestions]="menuSearchResult"
            (completeMethod)="onMenuSearch($event)" [autoHighlight]="true"
            [placeholder]="'Tìm kiếm chức năng' | translate" [delay]="200" [scrollHeight]="'400px'"
            class="sv-main-menu-search-box__input" [readonly]="!(arrMenu.length)"
            (onSelect)="onSelectMenuSuggestion($event)" (onBlur)="onBlurMenuSearchInput($event)"
            (onFocus)="onFocusMenuSearchInput($event)" field="label">
            <ng-template let-item pTemplate="item">
                <div innerHTML="{{ item.label | highlight : menuSearchInput}}"></div>
            </ng-template>
        </p-autoComplete>
    </div>
</div>