import { Component, OnInit, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { TreeNode } from 'primeng';
import { FolderFormComponent } from '../folder-form/folder-form.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { SecondPageIndexBase } from '../../../classes/base/second-page-index-base';
import { FsFoldersService } from '../../../services/fs-folders.service';
import { FsFilesService } from '../../../services/fs-files.service';
import { environment } from '../../../../../../../src/environments/environment';
@Component({
    selector: 'app-file-explorer-modal',
    templateUrl: './file-explorer-modal.component.html',
    styleUrls: ['./file-explorer-modal.component.scss']
})
export class FileExplorerModalComponent extends SecondPageIndexBase implements OnInit {

    @Output() closePopup = new EventEmitter<any>();
    @Output() closePopupMany = new EventEmitter<any>();
    itemFolder = { id: 0, idFsFolderCha: 0, ten: '' };
    isGlobal = true;
    dsPhanLoai = [{ label: 'Cá nhân', value: false }, { label: 'Chung', value: true }];
    breadcrumbItems: any[];
    @ViewChild('pEdit') pEdit: FolderFormComponent;
    @ViewChild('pUpload') pUpload: FileUploadComponent;
    @ViewChild('pFileViewer') pFileViewer: FileViewerComponent;
    isShow = false;

    currentFileViewerName = '';
    currentFileViewerUrl = '';
    cols = [];

    constructor(
        protected _FsFoldersService: FsFoldersService,
        protected _FsFilesService: FsFilesService,
        protected _injector: Injector,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'ten', header: this._translateService.instant('Tên'), visible: true, width: 'auto', },
            { field: 'totalSize', header: this._translateService.instant('Dung lượng'), visible: true, width: 'auto', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA_MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA_MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }

    editFolder() {
        const item = this.selectedItems[0];
        this.pEdit.showPopup({ id: item.id, parentFolderId: this.itemFolder.id, isGlobal: this.isGlobal });
    }

    addFolder() {
        this.pEdit.showPopup({ id: 0, parentFolderId: this.itemFolder.id, isGlobal: this.isGlobal });
    }

    edit() {
        const item = this.selectedItems[0];
        if (item.type == 2) {
            this.editFolder();
        }
    }
    delete() {
        const item = this.selectedItems[0];
        this._notifierService.showDeleteConfirm().then(rs => {
            if (item.type == 2) {
                this._FsFoldersService.delete(item.id)
                    .then(response => {
                        this._notifierService.showDeleteDataSuccess();
                        this.getData();
                    }, error => {
                        this._notifierService.showDeleteDataError();
                    });
            }
            else {
                this._FsFilesService.delete(item.id)
                    .then(response => {
                        this._notifierService.showDeleteDataSuccess();
                        this.getData();
                    }, error => {
                        this._notifierService.showDeleteDataError();
                    });
            }

        });
    }

    async deletelstMany() {
        let lstIdFolder = '';
        let lstIdFile = '';
        for (const item of this.selectedItems) {
            if (item.type == 2 && item.instanceId != '') {
                lstIdFolder += item.id + ',';
            }
            if (item.type == 1 && item.instanceId != '') {
                {
                    lstIdFile += item.id + ',';
                }

            }
        }
        this._notifierService.showDeleteConfirm().then(async rs => {
            if (lstIdFolder !== '') {
                console.log(lstIdFolder);
                await this._FsFoldersService.deleteMany(lstIdFolder).then(rs => {
                });
            }
            if (lstIdFile !== '') {
                console.log(lstIdFile);
                await this._FsFilesService.deleteMany(lstIdFile).then(rs => {
                });
            }

            await this.getData();
            this._notifierService.showDeleteDataSuccess();
        });

    }
    async getData() {

        // DataTable
        this.dataSource = [];
        if (this.itemFolder.id > 0) {
            if (this.itemFolder.idFsFolderCha > 0) {
                await this._FsFoldersService.getDetail(this.itemFolder.idFsFolderCha).then(rs => {
                    if (rs.success) {
                        this.dataSource.push({
                            id: rs.data.id,
                            idFsFolderCha: rs.data.idFsFolderCha,
                            trangThai: 1,
                            ten: '...',
                            type: 2,
                            instanceId: '',
                        });
                    }
                })
            }
            else {
                this.dataSource.push({
                    id: 0,
                    idFsFolderCha: 0,
                    trangThai: 1,
                    ten: '...',
                    type: 2,
                    instanceId: '',
                });
            }
        }

        await this._FsFoldersService.getsChildFolder(this.itemFolder.id, this.isGlobal, this.trangThai).then(rsFo => {
            if (rsFo.success) {
                rsFo.data.forEach(element => {
                    var foItem = {
                        id: element.id,
                        ten: element.ten,
                        totalSize: element.totalSize,
                        type: 2,
                        trangThai: element.trangThai,
                        idFsFolderCha: element.idFsFolderCha,
                        modified: element.modified,
                        modifiedByName: element.modifiedByName,
                        instanceId: element.instanceId,
                    };
                    this.dataSource.push(foItem);
                });
            }
        });

        await this._FsFilesService.getsByFolderId(this.itemFolder.id, this.isGlobal, this.trangThai).then(rsFi => {
            if (rsFi.success) {
                rsFi.data.forEach(element => {
                    var fiItem = {
                        id: element.id,
                        ten: element.ten,
                        totalSize: element.totalSize,
                        type: 1,
                        trangThai: element.trangThai,
                        modified: element.modified,
                        modifiedByName: element.modifiedByName,
                        instanceId: element.instanceId,
                        webPath: element.webPath,
                    };
                    this.dataSource.push(fiItem);
                });
            }
        })
    }
    async onItemClick(item: any) {
        if (item.type === 2) {
            this.itemFolder = item;
            //await this.checkNode(item.id);
            await this.getData();

        } else {
            // if (this._fileService.canBeOpenOnline(item)) {
            //     window.open(this._fileService.getLinkView(item.id), '_blank');
            // }
        }
    }

    uploadFiles() {
        this.pUpload.showPopup({ id: this.itemFolder.id, isGlobal: this.isGlobal });
    }


    //#endregion

    onSearch(): void {
        this.itemFolder = { id: 0, idFsFolderCha: 0, ten: '' };
        this.getData();
    }



    async viewFile() {
        const item = this.selectedItems[0];
        if (item.type === 2) {
            // Mở thư mục con
            this.onItemClick(item);
        } else {
            // Xem file online (nếu được hỗ trợ)
            if (this._FsFilesService.isSupportedViewOnline(item.ten)) {
                this.currentFileViewerName = item.ten;
                var fileUrl = this._FsFilesService.getAnonymousDownloadUrl(item.instanceId);
                this.pFileViewer.showPopup(true, { fileName: item.ten, fileUrl: fileUrl });
            } else {
                // download file
                top.location.href = await this._FsFilesService.getAnonymousDownloadUrl(item.instanceId);;
            }
        }
    }

    copyUrl() {
        const item = this.selectedItems[0];
        console.log(item);
        if (item.type === 1) {
            this.closePopup.next(`${environment.apiDomain.storageEndPoint}${item.webPath}`);
            this.isShow = false;
            // document.addEventListener('copy', (e: ClipboardEvent) => {
            //     e.clipboardData.setData('text/plain', (`${environment.apiDomain.storageEndPoint}${item.webPath}`));
            //     e.preventDefault();
            //     document.removeEventListener('copy', null);
            // });
            // document.execCommand('copy');
            // this._notifierService.showSuccess('Đã copy link vào clipboard');
        }
    }

    async copyUrls() {
        var data = [];
        await this.selectedItems.forEach(element => {
            if (element.type == 1) {
                data.push(`${environment.apiDomain.storageEndPoint}${element.webPath}`);

            }
        });
        this.closePopupMany.next(data);
        this.isShow = false;
    }

    onShowPopup(data: any) {
        this.isShow = true;
    }

    getMaxDialogHeight() {
        return (window.innerHeight - 200).toString() + 'px';
    }

}

