import { Component, OnInit, EventEmitter, Output, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector   : 'app-autocomplete-datasource',
    templateUrl: './autocomplete-datasource.component.html',
    styleUrls  : ['./autocomplete-datasource.component.scss'],
    providers  : [
        {
            provide    : NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteDatasourceComponent),
            multi      : true
        }
    ]
})
export class AutocompleteDatasourceComponent implements OnInit, ControlValueAccessor {
    onChange : Function;
    onTouched: Function;
            disabled         = false;
            dataSourceNow    = [];
    @Output() onValueChanged = new EventEmitter<any>();
    @Input() set suggestions(value: any[]) {
        this.dataSourceNow = value;
    };
    listData = [];
    selectedValue: any;

    constructor() { }

    ngOnInit() {
    }
    onValueChange(event: any): void {
        this.onChange(this.selectedValue);
        this.onValueChanged.next(this.selectedValue);
    }

    filterData(event) {
        const query = event.query.toLowerCase();
        if (query != "" && query != null) {
            const filtered: any[] = [];
            let   data            = this.dataSourceNow.filter(x => x.label.toLocaleLowerCase().indexOf(query) > -1);
            for (let i = 0; i < data.length; i++) {
                filtered.push(data[i].label);
            }
            this.listData = filtered;
        }
    }

    writeValue(obj: any): void {
        this.selectedValue = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
