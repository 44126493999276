<form [formGroup]="formGroup">
    <!-- <div class="sv-form-control">
        <div class="sv-form-item__input-wrapper">
            <button type="button" pTooltip="{{'LANGUAGE.VN' | translate}}" class="sv-btn -icon-only"
                (click)="changeLang('vn')">
                <img src="/assets/images/flag/vn.png">
            </button> &nbsp; &nbsp;
            <button type="button" pTooltip="{{'LANGUAGE.EN' | translate}}" class="sv-btn -icon-only"
                (click)="changeLang('en')">
                <img src="/assets/images/flag/en.png">
            </button>
        </div>
    </div> -->
    <div class="sv-form-item -required"
        [ngClass]="{'error': (formGroup.get('username').errors?.required && submitting)}">
        <div class="sv-form-item__label">{{'LOGIN_USERNAME' | translate}}</div>

        <div class="sv-form-item__input-wrapper -type-text-field">
            <input class="sv-form-item__input sv-text-field" type="text" (focus)="onFocus()"
                [(ngModel)]="model.username" formControlName="username"
                placeholder="{{'LOGIN_INPUTUSERNAME' | translate}}">
        </div>

        <div class="sv-form-item__message-container">
            <div class="sv-form-item__message">{{'FORM_REQUIRED' | translate}}</div>
        </div>
    </div>
    <div *ngIf="enableLogin">
        <div class="sv-form-item -required"
            [ngClass]="{'error': (formGroup.get('password').errors?.required && submitting) || error}">
            <div class="sv-form-item__label">{{'LOGIN_PASSWORD' | translate}}</div>

            <div class="sv-form-item__input-wrapper -type-text-field">
                <input class="sv-form-item__input sv-text-field" type="password" (focus)="onFocus()"
                    [(ngModel)]="model.password" (keyup.enter)="clickLogin()" formControlName="password"
                    placeholder="{{'LOGIN_INPUTPASSWORD' | translate}}">
            </div>

            <div class="sv-form-item__message-container" *ngIf="!error">
                <div class="sv-form-item__message">{{'FORM_REQUIRED' | translate}}</div>
            </div>

            <div class="__control-messages" *ngIf="error">
                <div class="__message-item">{{'LOGIN_ERROR' | translate}}</div>
            </div>
        </div>

        <div class="sv-page-login-password-options">
            <p-checkbox [(ngModel)]="model.remember" formControlName="remember" label="{{'LOGIN_REMEMBER' | translate}}"
                class="sv-checkbox sv-page-login-password-options__remember"></p-checkbox>

            <div class="sv-page-login-password-options__forgot h-cursor-pointer" (click)="goForgotPass()">
                {{'LOGIN_FORGOTPASSWORD' | translate}}
            </div>
        </div>

        <button type="button" (click)="clickLogin()"
            class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
            <span class="sv-btn__label">{{'LOGIN_BTNLOGIN' | translate}}</span>
            <span class="sv-btn__icon ci ci-arrow-right"></span>
        </button>

    </div>
    <div *ngIf="!enableLogin">
        <div class="sv-form-control type-text-field required"
            [ngClass]="{'error': (formGroup.get('email').errors?.required && submitting)}">
            <div class="sv-form-item__label">{{'LOGIN_EMAIL' | translate}}</div>

            <div class="sv-form-item__input-wrapper -type-text-field">
                <input class="sv-form-item__input sv-text-field" type="text" (focus)="onFocus()"
                    [(ngModel)]="model.email" formControlName="email" placeholder="{{'LOGIN_EMAIL' | translate}}">
            </div>

            <div class="sv-form-item__message-container">
                <div class="sv-form-item__message">{{'FORMREQUIRED' | translate}}</div>
            </div>
        </div>

        <div class="sv-page-login-password-options">
            <div class="sv-page-login-password-options__forgot h-cursor-pointer" (click)="goForgotPass()">
                {{'LOGIN_RETURN' | translate}}
            </div>
        </div>

        <button type="button" (click)="onResetPassword()"
            class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing-fast">
            <span class="sv-btn__label">{{'LOGIN_RESETPASS' | translate}}</span>
            <span class="sv-btn__icon ci ci-arrow-right"></span>
        </button>
    </div>

</form>