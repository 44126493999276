<p-dialog #dialog header="{{ 'Quản lý file' | translate }}" styleClass="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">

    <div class="sv-modal-content-panel sv-form-container">
        <div class="sv-page-content-area__body">
            <div class="sv-data-view-area">
                <div class="sv-data-view-area__main-block">
                    <div class="sv-table-wrapper">
                        <p-table [columns]="cols" [value]="dataSource" class="sv-table" [totalRecords]="totalRecord"
                            [customSort]="true" (onSort)="onSort($event)" [scrollable]="true" scrollHeight="auto"
                            [loading]="isLoading" [lazy]="true">
                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col style="width: 50px">
                                    <ng-container *ngFor="let col of cols">
                                        <col [style.width]="col.width ? col.width : 'auto'" [hidden]="!col.visible">
                                    </ng-container>
                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th>
                                        <p-triStateCheckbox class="sv-checkbox primary-theme" [(ngModel)]="isCheckAll"
                                            (onChange)="onChangeBulkSelection()"></p-triStateCheckbox>
                                    </th>
                                    <ng-container *ngFor="let col of columns">
                                        <th *ngIf="col.sort" [pSortableColumn]="col.field" [hidden]="!col.visible">
                                            <div class="flex-el space-between">
                                                <div class="overflow-break-word">{{col.header}}</div>
                                                <p-sortIcon [field]="col.field"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th *ngIf="!col.sort" [hidden]="!col.visible">
                                            <div class="overflow-break-word">{{col.header}}</div>
                                        </th>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                                <tr [class.sv-row-status]="rowData.trangThai"
                                    [class.sv-row-status-danger]="rowData.trangThai === 3"
                                    [class.sv-row-status-warning]="rowData.trangThai === 2"
                                    [class.sv-row-status-success]="rowData.trangThai === 1">
                                    <td>
                                        <p-checkbox class="sv-checkbox primary-theme" name="group" [value]="rowData"
                                            (onChange)="checkSelectionState()" [(ngModel)]="selectedItems"></p-checkbox>
                                    </td>
                                    <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field">
                                        <span *ngSwitchCase="'ten'">
                                            <a (click)="onItemClick(rowData)" style="cursor: pointer;">
                                                <span class="pull-left file-ex-icon"
                                                    [innerHTML]="rowData.ten | fileIcon : rowData.type == 2 : rowData.webPath"></span>
                                                <span>&nbsp;{{rowData.ten}}</span>
                                            </a>
                                        </span>
                                        <span *ngSwitchCase="'totalSize'">
                                            <span
                                                *ngIf="rowData.type == 1">{{rowData.totalSize | fileSize}}</span></span>
                                        <span
                                            *ngSwitchCase="'modified'">{{rowData.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                                        <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 sv-modal-footer-content__left-block">
                <button type="button" (click)="addFolder()"
                    class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active h-easing--flash">
                    <span class="sv-btn__label">{{'Thêm thư mục mới' | translate}}</span>
                    <span class="sv-btn__icon ci ci-add"></span>
                </button>
                <button type="button" (click)="uploadFiles()" *ngIf="itemFolder.id > 0"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash sv-icon-color--green-50 -focus-style-1">
                    <span class="sv-btn__label">{{'Tải file lên' | translate}}</span>
                    <span class="sv-btn__icon ci ci-upload"></span>
                </button>
            </div>

            <div class="ui-g-4 sv-modal-footer-content__right-block" [hidden]="selectedItems && !selectedItems.length">
                <button type="button" (click)="copyUrl()"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash"
                    [hidden]="isMultiEdit">
                    <span class="sv-btn__label">{{'Chọn ' | translate}} ({{selectedItems.length}}
                        {{'LIST_ROWS_SELECTED' | translate}})</span>
                    <span class="sv-btn__icon ci ci-copy"></span>
                </button>
                <button type="button" (click)="copyUrls()"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash"
                    [hidden]="!isMultiEdit">
                    <span class="sv-btn__label">{{'Chọn ' | translate}} ({{selectedItems.length}}
                        {{'LIST_ROWS_SELECTED' | translate}})</span>
                    <span class="sv-btn__icon ci ci-copy"></span>
                </button>
            </div>
        </div>
    </p-footer>

</p-dialog>

<app-folder-form (closePopup)="onPopupClosed($event)" #pEdit></app-folder-form>
<app-file-upload (closePopup)="onPopupClosed($event)" #pUpload></app-file-upload>

<app-file-viewer #pFileViewer [FileName]="currentFileViewerName" [FileUrl]="currentFileViewerUrl"></app-file-viewer>