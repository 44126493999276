import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import { AuthenticationService } from 'sv-shared';

@Injectable()
export class AuthGuardLoginService implements CanActivate {

    constructor(private auth: AuthenticationService, private router: Router) {
    }

    canActivate() {
        if (this.auth.isLoggedIn()) {
            this.router.navigateByUrl('/');
            return false;
        }
        return true;
    }
}
