import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';

@Pipe({
    name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

    transform(fileName: string, isFolder?: boolean, webPath?: string, width?: number, height?: number): string {
        if (!fileName) {
            return '';
        }

        if (!isFolder) {
            isFolder = false;
        }

        if (!width) {
            width = 50;
        }

        if (!height) {
            height = 50;
        }

        if (isFolder) {
            return `<img width='${width}' height='${height}' src='/assets/images/file-ext/icon.folder.png' />`;
        } else {
            const fileExtArr = fileName.split('.');
            const fileExt = fileExtArr[fileExtArr.length - 1];
            console.log(fileExt);
            if (this.isImgFile(fileExt)) {
                width = 50;
                height = 50;
                return `<img width='${width}' height='${height}' src='${environment.apiDomain.storageEndPoint}${webPath}' onerror='this.src = '/assets/images/file-ext/file-empty.png';' />`;
            } else {
                return `<img width='${width}' height='${height}' src='/assets/images/file-ext/icon.${fileExt}.png' onerror='this.src = '/assets/images/file-ext/file-empty.png';' />`;
            }
        }
    }

    isImgFile(_fileExt: string): boolean {
        var imgExts = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
        if (imgExts.findIndex(x => x == _fileExt) > -1) {
            return true;
        } else {
            return false;
        }
    }

}
