<div class="sv-notification-container">
    <ng-template ngFor let-group [ngForOf]="model" *ngIf="hasChildren()">
        <div class="sv-notification-group-label">{{group.label}}</div>

        <ng-template ngFor let-item [ngForOf]="group.items">
            <div class="sv-notification-item" [class.-unseen]="!item.isRead">
                <div class="sv-notification-item__icon ci" [ngClass]="{
                        'ci-chat': item.type === 1,
                        'ci-bookmark': item.type === 2,
                        'ci-edit': item.type === 3,
                        'ci-notification': item.type === 4
                    }" (click)="readItem(item)"></div>

                <div class="sv-notification-item__title">{{item.tieuDe}}</div>

                <div class="sv-notification-item__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
            </div>
        </ng-template>
    </ng-template>

    <ng-template ngFor let-item [ngForOf]="model" *ngIf="!hasChildren()">
        <div class="sv-notification-item" [class.-unseen]="!item.isRead">
            <div class="sv-notification-item__icon ci" [ngClass]="{
                        'ci-chat': item.type === 1,
                        'ci-bookmark': item.type === 2,
                        'ci-edit': item.type === 3,
                        'ci-notification': item.type === 4
                    }" (click)="readItem(item)"></div>

            <div class="sv-notification-item__title">{{item.tieuDe}}</div>

            <div class="sv-notification-item__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
        </div>
    </ng-template>
</div>