import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { UnauthorizeComponent } from './specific-page/Unauthorize/Unauthorize.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { ErrorPageNotFoundComponent } from './specific-page/not-found/not-found.component';
import { LoginComponent } from './specific-page/login/login.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardLoginService } from './services/auth-guard-login.service';
import { AppModule } from './app.module';
import { DashboardComponent } from './specific-page/dashboard/dashboard.component';

export const routes: Routes = [
    {
        path: '',
        component: DefaultLayoutComponent,
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: 'core', loadChildren: './sv-core/sv-core.module#SvCoreModule'
            },
            {
                path: 'mayna', loadChildren: './mayna/mayna.module#MaynaModule'
            },
            {
                path: 'error/404',
                component: ErrorPageNotFoundComponent
            }
        ],
        canActivate: [AuthGuardService]
    },
    {
        path: 'tai-khoan',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'dang-nhap',
                pathMatch: 'full'
            },
            {
                path: 'dang-nhap',
                component: LoginComponent
            }
        ],
        canActivate: [AuthGuardLoginService]
    },
    {
        path: 'unauthorize',
        component: UnauthorizeComponent
    },
    {
        path: '**',
        redirectTo: 'error/404'
    }
];

export const AppRoutes: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy });
