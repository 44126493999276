<div class="sv-page-content-area">
    <div class="sv-page-content-area__header">
        <!-- <div class="__page-breadcrumb-area">
            <sv-breadcrumb [items]="breadcrumbItems" [threshold]="4" [noTrailingSlash]="true"></sv-breadcrumb>
        </div> -->
        <div class="sv-page-title-area">
            <div class="sv-page-title-area__left-block">
                <h1 class="sv-page-title">
                    {{'Quản lý file' | translate}}
                    <span class="__helper ci ci-help"></span>
                </h1>
            </div>

            <div class="sv-page-title-area__right-block">

            </div>
        </div>
    </div>
    <div class="sv-page-content-area__body">
        <div class="sv-data-view-area">
            <div class="sv-data-view-area__left-block" [hidden]="!openLeftSidebar">
                <div class="sv-data-tree-container">
                    <div class="sv-data-tree-tool-block h-px-16 h-pt-16 sv-bgc--gray-10" [hidden]="!openTreeSearchAdv">
                        <div class="ui-g -small-gutter -row">
                            <div class="ui-g-12 h-mb-24">
                                <p-dropdown class="sv-dropdown" [options]="dsPhanLoai" [(ngModel)]="isGlobal">
                                </p-dropdown>
                            </div>
                            <div class="ui-g-12 h-mb-24">
                                <p-dropdown class="sv-dropdown" [filter]="true" [options]="arrTrangThai"
                                    [(ngModel)]="trangThai">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="ui-g -small-gutter -row">
                            <div class="ui-g-6 mb-24 text-right">
                                <button type="button" (click)="onSearch()"
                                    class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active h-easing--flash">
                                    <span class="sv-btn__label">{{'LIST_SEARCH' | translate}}</span>
                                    <span class="__icon ci ci-search"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="sv-data-tree-content-block">
                        <button (click)="toggleTreeSearchAdv()" [class.-activated]="openTreeSearchAdv"
                            class="sv-data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-color--primary"
                            type="button">
                            <span *ngIf="!openTreeSearchAdv" class="sv-btn__icon ci ci-data-1"></span>
                            <span *ngIf="openTreeSearchAdv" class="sv-btn__icon ci ci-close"></span>
                        </button>

                        <p-tree class="sv-tree" [value]="rootTreeNode" selectionMode="checkbox"
                            [style]="{'width': '100%'}" selectionMode="single" [(selection)]="selectedTreeNode"
                            (onNodeSelect)="nodeSelect($event)" [emptyMessage]="'Không có dữ liệu' | translate">
                        </p-tree>

                    </div>
                </div>
            </div>
            <div class="sv-data-view-area__main-block">
                <div class="sv-data-view-main-action-area">
                    <div class="sv-data-view-record-action-area__left-block" style="flex: 1 1 400px">
                        <button type="button"
                            class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                            (click)="toggleLeftSidebar()">
                            <span class="__icon ci ci-page-last" *ngIf="!openLeftSidebar"></span>
                            <span class="__icon ci ci-page-first" *ngIf="openLeftSidebar"></span>
                        </button>
                        <button type="button" (click)="addFolder()"
                            class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active h-easing--flash">
                            <span class="sv-btn__label">{{'Thêm thư mục mới' | translate}}</span>
                            <span class="sv-btn__icon ci ci-add"></span>
                        </button>
                        <button type="button" (click)="uploadFiles()" *ngIf="itemFolder.id > 0"
                            class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active h-easing--flash sv-icon-color--green-50 -focus-style-1">
                            <span class="sv-btn__label">{{'Tải file lên' | translate}}</span>
                            <span class="sv-btn__icon ci ci-upload"></span>
                        </button>
                    </div>
                </div>

                <div [hidden]="selectedItems && !selectedItems.length" class="sv-data-view-record-action-area">
                    <div class="sv-data-view-record-action-area__left-block" style="flex: 1 1 400px">
                        <div class="h-ml-16 h-mb-8 h-mr-28 sv-data-view-selected-record-number">
                            {{selectedItems.length}} {{'LIST_ROWS_SELECTED' | translate}}
                        </div>
                        <button type="button" (click)="copyUrl()"
                            class="sv-btn -icon-only sv-data-view-record-action-btn"
                            pTooltip="{{'Copy Link' | translate }}" [hidden]="isMultiEdit">
                            <span class="__icon ci ci-copy"></span>
                        </button>
                        <!-- <button type="button" (click)="edit()" class="sv-btn -icon-only sv-data-view-record-action-btn"
                            pTooltip="{{'FORM_EDIT' | translate }}" [hidden]="isMultiEdit">
                            <span class="sv-btn__icon ci ci-edit"></span>
                        </button> -->
                        <!-- <button type="button" (click)="viewFile()" class="sv-btn -icon-only sv-data-view-record-action-btn"
                            pTooltip="{{'FILE_EXPLORER.DETAIL' | translate }}" [hidden]="isMultiEdit">
                            <span class="__icon ci ci-search"></span>
                        </button> -->
                        <button type="button" (click)="delete()"
                            class="sv-btn -icon-only sv-data-view-record-action-btn"
                            pTooltip="{{'FORM_DELETE' | translate }}" [hidden]="isMultiEdit">
                            <span class="sv-btn__icon ci ci-delete sv-color--red-60"></span>
                        </button>
                        <button type="button" (click)="deletelstMany()"
                            class="sv-btn -icon-only sv-data-view-record-action-btn"
                            pTooltip="{{'FORM_DELETE' | translate }}" [hidden]="!isMultiEdit">
                            <span class="sv-btn__icon ci ci-delete sv-color--red-60"></span>
                        </button>
                    </div>

                    <div class="sv-data-view-record-action-area__right-block">
                        <button (click)="resetBulkSelect()"
                            class="sv-btn -icon-only sv-data-view-record-action-btn h-mr-8" type="button">
                            <span class="sv-btn__icon ci ci-close"></span>
                        </button>
                    </div>
                </div>

                <div class="sv-table-wrapper">
                    <p-table [columns]="cols" [value]="dataSource" class="sv-table" [totalRecords]="totalRecord"
                        [customSort]="true" (onSort)="onSort($event)" [scrollable]="true" scrollHeight="auto"
                        [loading]="isLoading" [lazy]="true">
                        <ng-template pTemplate="colgroup">
                            <colgroup>
                                <col style="width: 50px">
                                <ng-container *ngFor="let col of cols">
                                    <col [style.width]="col.width ? col.width : 'auto'" [hidden]="!col.visible">
                                </ng-container>
                            </colgroup>
                        </ng-template>

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th>
                                    <p-triStateCheckbox class="sv-checkbox primary-theme" [(ngModel)]="isCheckAll"
                                        (onChange)="onChangeBulkSelection()"></p-triStateCheckbox>
                                </th>
                                <ng-container *ngFor="let col of columns">
                                    <th *ngIf="col.sort" [hidden]="!col.visible" [pSortableColumn]="col.field">
                                        <div class="h-flex-el -h-space-between">
                                            <div class="h-overflow-break-word">{{col.header}}</div>
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th *ngIf="!col.sort" [hidden]="!col.visible">
                                        <div class="h-overflow-break-word">{{col.header}}</div>
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                            <tr [class.sv-row-status]="rowData.trangThai"
                                [class.sv-row-status-danger]="rowData.trangThai === 3"
                                [class.sv-row-status-warning]="rowData.trangThai === 2"
                                [class.sv-row-status-success]="rowData.trangThai === 1">
                                <td>
                                    <p-checkbox class="sv-checkbox primary-theme" name="group" [value]="rowData"
                                        (onChange)="checkSelectionState()" [(ngModel)]="selectedItems"></p-checkbox>
                                </td>
                                <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field">
                                    <span *ngSwitchCase="'ten'">
                                        <a (click)="onItemClick(rowData)" style="cursor: pointer;">
                                            <span class="pull-left file-ex-icon"
                                                [innerHTML]="rowData.ten | fileIcon : rowData.type == 2 : rowData.webPath"></span>
                                            <span>&nbsp;{{rowData.ten}}</span>
                                        </a>
                                    </span>
                                    <span *ngSwitchCase="'totalSize'">
                                        <span *ngIf="rowData.type == 1">{{rowData.totalSize | fileSize}}</span></span>
                                    <span
                                        *ngSwitchCase="'modified'">{{rowData.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                                    <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-folder-form (closePopup)="onPopupClosed($event)" #pEdit></app-folder-form>
<app-file-upload (closePopup)="onPopupClosed($event)" #pUpload></app-file-upload>

<app-file-viewer #pFileViewer [FileName]="currentFileViewerName" [FileUrl]="currentFileViewerUrl"></app-file-viewer>